const { clsx } = require("clsx");
const { twMerge } = require("tailwind-merge");

// Define the function cn
function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Export the function to make it accessible from other modules
module.exports = {
  cn,
};