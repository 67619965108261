import React, { useState, useEffect } from 'react';
import img1 from '../assets/T01.jpg' 
import img2 from '../assets/T02.jpg' 
import img3 from '../assets/T03.jpg' 
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';


const LeadershipSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    // Reset hover state when component unmounts
    return () => setHoveredIndex(null);
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
  //   <div id='team' className="py-20 bg-gray-50 w-screen">
  //   <div className="container mx-auto px-6 md:px-12 xl:px-32">
  //     <div className="mb-16 text-center">
  //       <p className='text-[#00df9a] text-center font-bold mt-20'>The Faces Behind Our Success</p>
  //       <h1 className="text-3xl font-bold text-center mt-3 mb-10">Meet Our Team</h1>
  //       <p className="text-gray-600 lg:w-8/12 lg:mx-auto">
  //         At Chalo Pharo Pakistan, our team is a dynamic blend of industry experts, passionate educators, and innovative thinkers.
  //         Together, we are dedicated to bridging the gap between academia and industry, driving creativity, and ensuring growth.
  //         Join us as we work towards a brighter future for Pakistan.
  //       </p>
  //     </div>
  //     <div className="grid gap-12 items-center md:grid-cols-3">
  //       {/* Member #1 */}
  //       <div className="space-y-4 text-center relative">
  //         <div className={`transition-all duration-300 ease-in-out transform ${hoveredIndex === 1 ? 'scale-105' : 'scale-100'}`}
  //              onMouseEnter={() => handleMouseEnter(1)}
  //              onMouseLeave={handleMouseLeave}>
  //           <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
  //                src={img2}
  //                alt="Dr. Muhammed Umer" loading="lazy" />
  //           <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
  //           </div>
  //         </div>
  //         <div>
  //           <h4 className="text-2xl text-black">Dr. Muhammed Umer</h4>
  //           <span className="block text-sm text-gray-500">Founder - C2P</span>
  //           <span className="block text-sm text-gray-500">CEO - 128</span>
  //           <div className="flex justify-center space-x-4 mt-2">
  //             <a href="mailto:umerceo128@gmail.com" className="text-blue-500 hover:text-blue-700">
  //               <FaEnvelope size={20} />
  //             </a>
  //             <a href="https://www.linkedin.com/in/muhammad-umer-alvi-53298b20a/" className="text-blue-500 hover:text-blue-700">
  //               <FaLinkedin size={20} />
  //             </a>
  //           </div>
  //         </div>
  //       </div>
 
  //       {/* Member #2 */}

  //       <div className="space-y-4 text-center relative">
  //         <div className={`transition-all duration-300 ease-in-out transform ${hoveredIndex === 0 ? 'scale-105' : 'scale-100'}`}
  //              onMouseEnter={() => handleMouseEnter(0)}
  //              onMouseLeave={handleMouseLeave}>
  //           <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
  //                src={img1}
  //                alt="Ali Pirzada" loading="lazy" />
  //           <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
  //           </div>
  //         </div>
  //         <div>
  //           <h4 className="text-2xl text-black">Ali Pirzada</h4>
  //           <span className="block text-sm text-gray-500">Co-Founder</span>
  //           <div className="flex justify-center space-x-4 mt-2">
  //             <a href="mailto:hr@128technologies.com.pk" className="text-blue-500 hover:text-blue-700">
  //               <FaEnvelope size={20} />
  //             </a>
  //             <a href="https://www.linkedin.com/in/ali-pirzada" className="text-blue-500 hover:text-blue-700">
  //               <FaLinkedin size={20} />
  //             </a>
  //           </div>
  //         </div>
  //       </div>

        

  //       {/* Member #3 */}
  //       <div className="space-y-4 text-center relative">
  //         <div className={`transition-all duration-300 ease-in-out transform ${hoveredIndex === 2 ? 'scale-105' : 'scale-100'}`}
  //              onMouseEnter={() => handleMouseEnter(2)}
  //              onMouseLeave={handleMouseLeave}>
  //           <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
  //                src={img3}
  //                alt="M. Ayyan Bhatti" loading="lazy" />
  //           <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
  //           </div>
  //         </div>
  //         <div>
  //           <h4 className="text-2xl text-black">M. Ayyan Bhatti</h4>
  //           <span className="block text-sm text-gray-500">Administration</span>
  //           <div className="flex justify-center space-x-4 mt-2">
  //             <a href="mailto:ayyan@example.com" className="text-blue-500 hover:text-blue-700">
  //               <FaEnvelope size={20} />
  //             </a>
  //             <a href="www.linkedin.com/in/muhammad-ayyan-0b00a4194" className="text-blue-500 hover:text-blue-700">
  //               <FaLinkedin size={20} />
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
<div id='team' className="py-20 bg-gray-50 w-screen">
      <div className="container mx-auto px-6 md:px-12 xl:px-32">
        <div className="mb-16 text-center">
          <p className='text-[#00df9a] text-center font-bold mt-20'>The Faces Behind Our Success</p>
          <h1 className="text-3xl font-bold text-center mt-3 mb-10">Meet Our Team</h1>
          <p className="text-gray-600 lg:w-8/12 lg:mx-auto">
            At Chalo Pharo Pakistan, our team is a dynamic blend of industry experts, passionate educators, and innovative thinkers.
            Together, we are dedicated to bridging the gap between academia and industry, driving creativity, and ensuring growth.
            Join us as we work towards a brighter future for Pakistan.
          </p>
        </div>
        <div className="grid gap-12 items-center md:grid-cols-3">
          {/* Member #1 */}
          <div className="space-y-4 text-center relative">
            <div className={`transition-all duration-300 ease-in-out transform ${hoveredIndex === 1 ? 'scale-105' : 'scale-100'}`}
                 onMouseEnter={() => handleMouseEnter(1)}
                 onMouseLeave={handleMouseLeave}>
              <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-40 lg:w-64 lg:h-64"
                   src={img2}
                   alt="Dr. Muhammed Umer" loading="lazy" />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
              </div>
            </div>
            <div>
              <h4 className="text-2xl text-black">Dr. Muhammed Umer</h4>
              <span className="block text-sm text-gray-500">Founder - C2P</span>
              <span className="block text-sm text-gray-500">CEO - 128</span>
              <div className="flex justify-center space-x-4 mt-2">
                <a href="mailto:umerceo128@gmail.com" className="text-[#00df9a] hover:text-[#068a48]">
                  <FaEnvelope size={20} />
                </a>
                <a href="https://www.linkedin.com/in/muhammad-umer-alvi-53298b20a/" className="text-[#00df9a] hover:text-[#068a48]">
                  <FaLinkedin size={20} />
                </a>
              </div>
            </div>
          </div>

          {/* Member #2 */}
          <div className="space-y-4 text-center relative">
            <div className={`transition-all duration-300 ease-in-out transform ${hoveredIndex === 0 ? 'scale-105' : 'scale-100'}`}
                 onMouseEnter={() => handleMouseEnter(0)}
                 onMouseLeave={handleMouseLeave}>
              <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
                   src={img1}
                   alt="Ali Pirzada" loading="lazy" />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
              </div>
            </div>
            <div>
              <h4 className="text-2xl text-black">Ali Pirzada</h4>
              <span className="block text-sm text-gray-500">Co-Founder</span>
              <div className="flex justify-center space-x-4 mt-2">
                <a href="" className="text-[#00df9a] hover:text-[#068a48]">
                  <FaEnvelope size={20} />
                </a>
                <a href="" className="text-[#00df9a] hover:text-[#068a48] ">
                  <FaLinkedin size={20} />
                </a>
              </div>
            </div>
          </div>


      

          {/* Member #3 */}
          <div className="space-y-4 text-center relative">
            <div className={`transition-all duration-300 ease-in-out transform ${hoveredIndex === 2 ? 'scale-105' : 'scale-100'}`}
                 onMouseEnter={() => handleMouseEnter(2)}
                 onMouseLeave={handleMouseLeave}>
              <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
                   src={img3}
                   alt="M. Ayyan Bhatti" loading="lazy" />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
              </div>
            </div>
            <div>
              <h4 className="text-2xl text-black">M. Ayyan Bhatti</h4>
              <span className="block text-sm text-gray-500">Administration</span>
              <div className="flex justify-center space-x-4 mt-2">
                <a href="hr@128technologies.com.pk" className="text-[#00df9a] hover:text-[#068a48]">
                  <FaEnvelope size={20} />
                </a>
                <a href="www.linkedin.com/in/muhammad-ayyan-0b00a4194" className="text-[#00df9a] hover:text-[#068a48]">
                  <FaLinkedin size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default LeadershipSection;
