import React from "react";
import img from '../assets/CH1.jpg';
import img2 from '../assets/CH2.jpg';
import img3 from '../assets/CH3.jpg';
import img4 from '../assets/CH4.jpg';

const History = () => {
  return (
    <div className="bg-white py-10 px-5 pt-10 justify-center w-screen">
      <p className='text-[#00df9a] text-center font-bold mt-20'>Growth and Expansion</p>
      <h1 className="text-3xl font-bold text-center mt-8 md:mt-3 mb-10">History</h1>

      <div className="flex flex-col md:flex-row justify-center items-center gap-4 bg-white p-4">
  {/* Card 1 */}
  <div className="flex flex-col m-4 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-white md:max-w-xl md:flex-row transform transition-transform duration-300 hover:scale-105 h-auto md:h-[24rem]">
    <img className="h-64 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={img} alt="First Cohort" />
    <div className="flex flex-col justify-center p-6">
      <h5 className="mb-2 text-xl font-medium text-black text-left">First Cohort</h5>
      <p className="mb-4 text-base text-black text-left">We started in 2022 in summer by having first coholt of 35 UG students representing 07 Universities of enterprising young Pakistanis. They have been working on various hi tech projects which have the potential to bring social change; they are there to make a difference.</p>
    </div>
  </div>

  {/* Card 2 */}
  <div className="flex flex-col m-4 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-white md:max-w-xl md:flex-row transform transition-transform duration-300 hover:scale-105 h-auto md:h-[24rem]">
    <img className="h-64 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={img2} alt="Second Cohort" />
    <div className="flex flex-col justify-center p-6">
      <h5 className="mb-2 text-xl font-medium text-black text-left">Second Cohort</h5>
      <p className="mb-4 text-base text-black text-left">We had our Second Cohort of Chalo Parho Pakistan interns under VSTIC (Virtual Science and Technology Incubation Centre), named CP-V1. We had five great ideas assigned to our participants, with weekly progress updates. A Microsoft USA representative spoke to our participants, motivating them to excel. Additionally, a team from Romania conducted a 30-minute session on developing blockchain applications and integrating them at blockchain level-1.</p>
    </div>
  </div>
</div>

      <div className="flex flex-col md:flex-row justify-center items-center gap-4 bg-white">
        {/* Card 3 */}
        <div className="flex flex-col m-4 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-white md:max-w-xl md:flex-row transform transition-transform duration-300 hover:scale-105 ">
          <img className="h-64 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={img3} alt="Third Cohort" />
          <div className="flex flex-col justify-center p-6">
            <h5 className="mb-2 text-xl font-medium text-black text-left">Third Cohort</h5>
            <p className="mb-4 text-base text-black text-left">Third Cohort comprising of 40 cohorts at Air University Islamabad from 5Th July 2023</p>
          </div>
        </div>

        {/* Card 4 */}
        <div className="flex flex-col m-4 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-white md:max-w-xl md:flex-row transform transition-transform duration-300 hover:scale-105">
          <img className="h-64 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={img4} alt="Fourth Cohort" />
          <div className="flex flex-col justify-center p-6">
            <h5 className="mb-2 text-xl font-medium text-black text-left">Fourth Cohort</h5>
            <p className="mb-4 text-base text-black text-left">The 4th cohort consisting of 20 young talented students began on June 24th.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
