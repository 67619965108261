import React from 'react';
import { FaHome, FaFlask, FaBriefcase, FaProjectDiagram, FaLightbulb, FaDollarSign } from 'react-icons/fa';


<span className="grid h-20 w-20 place-items-center rounded-full bg-green-500 transition-all duration-300 group-hover:bg-green-400">
  <FaHome className="h-10 w-10 text-white transition-all" />
</span>

export default function CardGrid() {
  return (
    <div id='working' className="bg-white px-5 py-4 my-16">
    <p className='text-[#00df9a] text-center font-bold mt-20'>Working</p>
    <h1 className="text-3xl font-bold text-center mt-3 mb-20">OUR APPROACH</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-16 mt-5 mx-5 sm:mx-10 lg:mx-20 xl:mx-32 2xl:mx-40">

      {/* Card 1 */}
      <div className="group relative cursor-pointer overflow-hidden bg-white min-h-300px px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl rounded-lg">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-green-500 transition-all duration-300 group-hover:scale-[15]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-green-500 transition-all duration-300 group-hover:bg-green-400">
            <FaFlask className="h-10 w-10 text-white transition-all" />
          </span>
          <div className="space-y-6 pt-7 leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <h2 className="text-black font-bold">Building a Student Pool</h2>
            <p>At Chalo Parho Pakistan, we bring together a bright and varied group of students from different universities. We believe that young minds have the power to drive innovation and creativity, and are committed to helping them reach their full potential.</p>
          </div>
        </div>
      </div>

      {/* Card 2 */}
      <div className="group relative cursor-pointer overflow-hidden bg-white min-h-300px px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl rounded-lg">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-blue-500 transition-all duration-300 group-hover:scale-[15]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-blue-500 transition-all duration-300 group-hover:bg-blue-400">
            <FaFlask className="h-10 w-10 text-white transition-all" />
          </span>
          <div className="space-y-6 pt-7 leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <h2 className="text-black font-bold">Research and Development</h2>
            <p>Our R&D team is constantly exploring new ways to enhance our educational offerings. We believe in the power of innovation to improve learning experiences and outcomes.</p>
          </div>
        </div>
      </div>

      {/* Card 3 */}
      <div className="group relative cursor-pointer overflow-hidden bg-white min-h-300px px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl rounded-lg">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-red-500 transition-all duration-300 group-hover:scale-[15]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-red-500 transition-all duration-300 group-hover:bg-red-400">
            <FaBriefcase className="h-10 w-10 text-white transition-all" />
          </span>
          <div className="space-y-6 pt-7 leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <h2 className="text-black font-bold">Securing Industry Projects</h2>
            <p>We partner with top industry players through 128 Tech and our customers include PTCL Academy, Armed forces and various public organizations, to secure meaningful projects. These partnerships give our students possibilities for growth and real-world experience.</p>
          </div>
        </div>
      </div>

      {/* Card 4 */}
      <div className="group relative cursor-pointer overflow-hidden bg-white min-h-300px px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl rounded-lg">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-yellow-500 transition-all duration-300 group-hover:scale-[15]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-yellow-500 transition-all duration-300 group-hover:bg-yellow-400">
            <FaProjectDiagram className="h-10 w-10 text-white transition-all" />
          </span>
          <div className="space-y-6 pt-7 leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <h2 className="text-black font-bold">Project Execution</h2>
            <p>Our students and specialists collaborate to bring projects to life with accuracy, originality, and innovation. We partner with top industry businesses, securing significant deals, offering students development opportunities and exposure.</p>
          </div>
        </div>
      </div>

      {/* Card 5 */}
      <div className="group relative cursor-pointer overflow-hidden bg-white min-h-300px px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl rounded-lg">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-purple-500 transition-all duration-300 group-hover:scale-[15]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-purple-500 transition-all duration-300 group-hover:bg-purple-400">
            <FaLightbulb className="h-10 w-10 text-white transition-all" />
          </span>
          <div className="space-y-6 pt-7 leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <h2 className="text-black font-bold">Innovation Hub</h2>
            <p>We foster a culture of innovation by providing resources and support for students to develop and implement new ideas. Our Innovation Hub is a space where creativity and technology intersect.</p>
          </div>
        </div>
      </div>

      {/* Card 6 */}
      <div className="group relative cursor-pointer overflow-hidden bg-white min-h-300px px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl rounded-lg">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-pink-500 transition-all duration-300 group-hover:scale-[15]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-pink-500 transition-all duration-300 group-hover:bg-pink-400">
            <FaDollarSign className="h-10 w-10 text-white transition-all" />
          </span>
          <div className="space-y-6 pt-7 leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <h2 className="text-black font-bold">Attracting Investments</h2>
            <p>We regularly look for investments to support the expansion and success of our startups. By establishing connections with investors, we are able to obtain the funding required to transform concepts into profitable businesses, guaranteeing sustainability and expansion over time.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  );
}
