import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="bg-black">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        {/* <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          <div className="px-5 py-2">
            <a className="text-base leading-6 text-gray-500 hover:text-#00df9a" >
              About
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="#" className="text-base leading-6 text-gray-500 hover:text-#00df9a">
              Blog
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="#" className="text-base leading-6 text-gray-500 hover:text-#00df9a">
              Team
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="#" className="text-base leading-6 text-gray-500 hover:text-#00df9a">
              Pricing
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="#" className="text-base leading-6 text-gray-500 hover:text-#00df9a">
              Contact
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="#" className="text-base leading-6 text-gray-500 hover:text-#00df9a">
              Terms
            </a>
          </div>
        </nav> */}
        <div className="flex justify-center mt-8 space-x-6">
  <a 
    href="https://web.facebook.com/people/CHALO-PARHO-Pakistan/100094019642825/?mibextid=LQQJ4d&_rdc=1&_rdr" 
    className="text-gray-400 hover:text-#00df9a"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="sr-only">Facebook</span>
    <FaFacebookSquare className="w-6 h-6" />
  </a>
  <a 
    href="https://www.instagram.com/128_technology/" 
    className="text-gray-400 hover:text-#00df9a"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="sr-only">Instagram</span>
    <FaInstagram className="w-6 h-6" />
  </a>
  <a 
    href="https://www.linkedin.com/company/128-technologies/posts/?feedView=all" 
    className="text-gray-400 hover:text-#00df9a"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="sr-only">Linkedin</span>
    <FaLinkedin className="w-6 h-6" />
  </a>
</div>

        <p className="mt-8 text-base leading-6 text-center text-gray-400">
          © 2024 C2C, Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
