import React, { useEffect } from 'react';
import { IoRocketOutline } from "react-icons/io5";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Cards = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with desired options
  }, []);

  return (

    <div id = 'next-section' className='pt-36 pb-36 px-4 bg-white relative -mt-10 w-screen'>
        <p className='text-[#00df9a] text-center font-bold'>Milestones</p>
             <h1 className="text-3xl font-bold text-center mt-4 mb-20">Key Deliverables</h1>
         
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 bg-transparent'>
          <div className='w-16 h-16 mx-auto mt-[-3rem] bg-transparent flex justify-center items-center'>
            <IoRocketOutline className='w-full h-full text-[#00df9a]' />
          </div>
          <h2 className='text-2xl font-bold text-center py-8'>Industry Solutions</h2>
          <div className='text-center font-medium'>
            <p className='py-2 border-b mx-8 mt-8'>Economical projects, products, and services to industry</p>
            <p className='py-2 border-b mx-8'>Experts Projects “Green Label” products, and services</p>
            <p className='py-2 border-b mx-8'>Covers Embedded & Hardware</p>
          </div>
        </div>
        <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300 bg-transparent'>
          <div className='w-16 h-16 mx-auto mt-[-3rem] bg-transparent flex justify-center items-center'>
            <AiOutlineFundProjectionScreen className='w-full h-full text-[#00df9a]' />
          </div>
          <h2 className='text-2xl font-bold text-center py-8'>Startups & Growth</h2>
          <div className='text-center font-medium'>
            <p className='py-2 border-b mx-8 mt-8'>Industry-driven startups adding to micro niches (less chance of failure).</p>
            <p className='py-2 border-b mx-8'>Hi-Tech / Med-Tech SMEs (Mushroom Growth).</p>
          </div>
        </div>
        <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 bg-transparent'>
          <div className='w-16 h-16 mx-auto mt-[-3rem] bg-transparent flex justify-center items-center'>
            <AiOutlineCodeSandbox className='w-full h-full text-[#00df9a]' />
          </div>
          <h2 className='text-2xl font-bold text-center py-8'>Innovation & Expansion</h2>
          <div className='text-center font-medium'>
            <p className='py-2 border-b mx-8 mt-8'>Transforming research into impactful solutions and startups
</p>
            <p className='py-2 border-b mx-8'>Connecting students with real-world industry projects
            </p>
            <p className='py-2 border-b mx-8'>Securing investments to scale innovative startups</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
