import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from '../assets/M01.jpg';

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with desired options
  }, []);

  return (
    <div className="hero bg-white -mb-52 flex flex-col justify-center items-center min-h-screen md:w-screen">
      <div className="container py-8 px-4 md:px-0 flex flex-col md:flex-row md:justify-center md:items-center">
        <div className="md:w-1/2 lg:w-1/3 md:mr-8" data-aos="fade-right">
          <p className="text-[#00df9a] text-center md:text-left font-bold mt-4 md:mt-20">Vision and Values</p>
          <h1 className="text-3xl font-bold text-center md:text-left mt-3 mb-6 md:mb-10">Our Mission</h1>
          <p className="text-lg text-center md:text-left mb-4 md:px-4">
            C2P is committed to transforming the way academia and industry interact. Our mission is to provide a dynamic online platform that enables university and college students to engage in real-world industrial projects while honing their technical and business skills. Through our innovative approach, we aim to empower students to turn their unique ideas into reality, connect with potential investors, and contribute to the growth and advancement of industries.
          </p>
        </div>
        <div className="md:w-1/2 lg:w-2/4 mt-8 md:mt-0 flex justify-center" data-aos="fade-left">
          <img
            src={img}
            alt="Hero Image"
            className="w-auto h-full object-cover transform scale-90 md:scale-80 rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
