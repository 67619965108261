import React, { useEffect } from 'react';
import img from '../assets/img2.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with desired options
  }, []);

  return (
    <div id='about' className="bg-white">
      <div className="container mx-auto py-8 px-4 md:flex md:flex-row md:justify-center md:items-center">
        <div data-aos="fade-right" className="md:w-1/2 lg:w-2/3 md:mr-8">
          <img
            src={img}
            alt="Responsive Design"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div data-aos="fade-left" className="md:w-1/2 lg:w-1/3 md:ml-8">
          <p className='text-[#00df9a] text-left font-bold mt-10 md:mt-0'>Who We Are</p>
          <h1 className="text-3xl font-bold text-left mt-3 mb-4 md:mb-8">ABOUT US</h1>
          <p className="text-lg text-left mb-4">
            We are a setup comprising of experience and exuberance collectively creating synergy in our working environment. We specialize in bringing ideas to life, creating products, and reverse-engineering hi-tech systems.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
