import React from 'react';
import Analytics from './components/About';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Team from './components/Team';
import History from './components/History';
import Features from './components/Working';
import Contact from './components/Contact'
import Mision from './components/Mission';
import InfiniteMovingCardsDemo  from './components/InfiniteMovingCardsDemo ';


function App() {
  

  return (
    <div>
      <Navbar />
      <Hero />
      <Cards />
  <Analytics />
       <Features/>
       <Mision/>
     <History/>      
     <InfiniteMovingCardsDemo /> 
      <Team />
      <Contact/>
      <Footer />

    </div>
  );
}

export default App;
