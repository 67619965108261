"use client";

import React from "react";
import Success from "../components/Success"; // Assuming you renamed the infinite-moving-cards component to Success

const InfiniteMovingCardsDemo = () => {
  return (
    <div className="h-[40rem] rounded-md flex flex-col antialiased bg-white dark:bg-black dark:bg-grid-white/[0.05] items-center justify-center relative overflow-hidden">
      <p className='text-[#00df9a] text-center font-bold mt-20'>Success Stories</p>
      <h1 className="text-3xl font-bold text-center mt-3 mb-20">ACCOMPLISHMENTS</h1>

      <Success items={testimonials} direction="right" speed="slow" />
    </div>
  );
};

const testimonials = [
  {
    quote: "Has a fully funded PhD from South Korea. Published multiple research papers in international journals",
    name: "Turab",
    title: "Fully Funded PhD (Korea)",
  },
  {
    quote: " Technologies as Tech Lead DevelopmentsKnown for pioneering advancements in cybersecurity. Mentors young entrepreneurs and startup founders in the tech community.",
    name: "Muhammad Arslan",
    title: " Founded own company & working with 128",
  },
  {
    quote: "Skilled in robotics and automation, with numerous accolades. Conducted workshops on robotics for university students across Pakistan.",
    name: "Abdullah, Hamza",
    title: "Represented Pakistan in Robocon 2019, Turkey. Defeated India",
  },
  {
    quote: "Expertise in software development and project management. Actively involved in community service and tech mentorship programs.",
    name: "Zulaid",
    title: "Fresh graduate earning a handsome salary (Lahore)",
  },
  {
    quote: "Focuses on embedded systems and IoT solutions. Collaborated with top tech firms in Sweden on innovative projects.​",
    name: "Fakhir",
    title: "MS Electronics (Sweden)",
  },
  {
    quote: "Specializes in AI-driven solutions for various industries. Recently secured significant funding for expanding their operations globally.​",
    name: "Zanbeel ",
    title: "Spin-off of 128 Technologies, founded by our employees. Office at NSTP.",
  },
  {
    quote: "Several have transitioned into full-time roles at top tech companies. Continuously contribute to the innovation pipeline with fresh ideas and projects​",
    name: "Internees  ",
    title: "Developed successful FYPs through our projects",
  },
];

export default InfiniteMovingCardsDemo;
