import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setNav(false);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateNavbarVisibility = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', updateNavbarVisibility);
    return () => {
      window.removeEventListener('scroll', updateNavbarVisibility);
    };
  }, []);

  return (
    <>

      <div className="fixed top-0 left-0 w-screen h-24 px-4 text-white bg-transparent z-10 transition-transform duration-300">
        <div className="flex justify-between items-center h-full">
          {/* Uncomment the line below to display your logo */}
          {/* <img src={logo} alt="Logo" className="h-10 cursor-pointer" /> */}
          <ul className="hidden md:flex mx-auto space-x-8 justify-center items-center">
            <li className="p-4 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('home')}>Home</li>
            <li className="p-4 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('about')}>About</li>
            <li className="p-4 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('working')}>Working</li>
            <li className="p-4 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('team')}>Team</li>
            <li className="p-4 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('contact')}>Contact</li>
          </ul>
          <div onClick={handleNav} className="block md:hidden cursor-pointer">
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
        </div>

        {/* Mobile Menu */}
        <ul className={`fixed top-0 left-0 w-screen h-content bg-[#000300] ease-in-out duration-500 ${nav ? 'translate-x-0' : '-translate-x-full'}`}>
          {/* Uncomment the line below to display your logo in the mobile menu */}
          {/* <img src={logo} alt="Logo" className="h-10 m-4 cursor-pointer" /> */}
          <li className="p-4 border-b border-gray-600 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('home')}>Home</li>
          <li className="p-4 border-b border-gray-600 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('about')}>About</li>
          <li className="p-4 border-b border-gray-600 cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('working')}>Working</li>
          <li className="p-4 border-b border-gray-600 cursor-pointer  hover:text-[#00df9a]" onClick={() => handleScroll('team')}>Team</li>
          <li className="p-4  cursor-pointer hover:text-[#00df9a]" onClick={() => handleScroll('contact')}>Contact</li>
        </ul>
      </div>

    </>
  );
};

export default Navbar;
