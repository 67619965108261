import React, { useEffect } from 'react';
import Typed from 'react-typed';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from '../assets/hero.jpeg';
import ArrowButton from './ArrowButton'; // Ensure correct import path

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with desired options
  }, []);

  return (
    <div id='home'    
    className="relative bg-cover bg-center bg-no-repeat text-white bg-fixed w-full"
    style={{ backgroundImage: `url(${img})` }}
    >
      <div className="absolute inset-0 bg-black/70"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <div
          data-aos="fade-up"
          className="max-w-xl text-center sm:text-left mx-auto flex flex-col justify-center"
        >
          <p className='text-[#00df9a] text-center font-bold p-2'>
            A Movement by 128 Technologies Pvt. Ltd.
          </p>
          <h1 className='md:text-7xl sm:text-6xl text-center text-4xl font-bold md:py-6'>
            Chalo Parho Pakistan​ (C2P)​
          </h1>
          <div className='flex justify-center items-center p-9'>
            <Typed
              className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
              strings={['Creativity', 'Skills', 'Development']}
              typeSpeed={120}
              backSpeed={140}
              loop
            />
          </div>
          <p className='md:text-2xl text-xl  text-center font-bold text-white-500 pt-8'>
            A movement that shall serve to bridge the gap between youth and multinational business
          </p>
          <ArrowButton targetId="next-section" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
