import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaMailBulk } from 'react-icons/fa';

const Contact = () => {
  return (
    <div id="contact" className="py-20 bg-gray-50 w-screen">
    <div className="container mx-auto px-6 md:px-12 xl:px-32 text-center">
      <h2 className="text-3xl font-bold text-center text-black mb-10">Contact Us</h2>
      <p className="text-gray-600 mb-16">Want to get in touch? We'd love to hear from you. Here's how you can reach us:</p>
      
      <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-4">
        {/* Contact */}
        <div className="flex flex-col items-center">
          <FaPhone size={30} className="text-[#00df9a] mb-4" />
          <h4 className="text-xl font-semibold text-black">Call Us</h4>
          <p className="text-gray-600">+92-333-5525660</p>
        </div>
        
        {/* Address */}
        <div className="flex flex-col items-center">
          <FaMapMarkerAlt size={30} className="text-[#00df9a] mb-4" />
          <h4 className="text-xl font-semibold text-black">Address</h4>
          <p className="text-gray-600">PTCL Academy, H-9/4, Islamabad</p>
        </div>
        
        {/* Email Us */}
        <div className="flex flex-col items-center">
          <FaEnvelope size={30} className="text-[#00df9a] mb-4" />
          <h4 className="text-xl font-semibold text-black">Email Us</h4>
          <p className="text-gray-600">info@128technologies.com.pk</p>
        </div>
        
        {/* Mailing */}
        <div className="flex flex-col items-center">
          <FaMailBulk size={30} className="text-[#00df9a] mb-4" />
          <h4 className="text-xl font-semibold text-black">Mailing</h4>
          <p className="text-gray-600">House no.369, st.no 14, G-10/2, Islamabad</p>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Contact;
